import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { storedUserData } from '../helpers/const-helper';

@Injectable({providedIn:'root'})
export class canLogin implements CanActivate {

    constructor(
    	private router: Router,
      private permissionsService: NgxPermissionsService,
    ) { }

    canActivate() {
      let user = storedUserData();
       if(user) {

           let permissions = Object.keys(this.permissionsService.getPermissions());
           this.permissionsService.loadPermissions([...permissions,user.chUserDTO.userType]);

           if(user.chUserDTO.userType == "SUPER_ADMIN") {

               this.router.navigate(['admin-management/list-administrator'])
           } else if(user.chUserDTO.userType == "ADMIN") {

               this.router.navigate(['/trips'])
           }else if(user.chUserDTO.userType == "INCHARGE_ADMIN") {
               this.router.navigate(['/admin-management/list-administrator'])
              // this.router.navigate(['/trips'])

           }
           return false;
       } else {
           return true;
       }
    }
}
